/* -----font------ */
body {
  margin: 0;
  padding: 0;
}

html {
  width: 100%;
  margin: 0;
}

.App {
  width: 100vw;
  text-align: center;
}

/* !!!!!!!!! Need to use this font for all H-tags !!!!!!!!! */
@media only screen and (max-width: 1023px) {

p {
  font-size: 15px;
}

@font-face {
  font-family: 'Hello Farmer';
  src: local('Hello Farmer'), url(./fonts/Hello\ Farmer.ttf)
}

.farmer {
  font-family: 'Hello Farmer';
}

/* -----colors------ */

.yellow-bg {
  background-color: #f9da15;
}

.yellow {
  color: #f9da15 !important;
}

.magenta-bg {
  background-color: #e91572;
}

.magenta {
  color: #e91572 !important;
}

.teal-bg {
  background-color: #3bf3d7;
}

.teal {
  color: #3bf3d7 !important;
}

.blue-bg {
  background-color: #56d0fa;
}

.blue {
  color: #56d0fa !important;
}

.lime-bg {
  background-color: #e4f20b;
}

.lime {
  color: #e4f20b !important;

}

.purple-bg {
  background-color: #6b1dbe;
}

.purple {
  color: #6b1dbe !important;
}

.green-bg {
  background-color: #1cc70e;
}

.green {
  color: #1cc70e !important;
}

body {
  max-width: 1440px;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

/* ------------NAV SECTION--------------  */

/* !!!change once drawer is ready!!! */

.drawer {
  position: relative;
  z-index: 1000;
 }

 #menuIcon {
 }

 .drawerList {
  display: flex;
  flex-direction: column;
  font-size: 25px;
  height: 100%;
} 

.listItem {
  display: flex;
  flex-direction: column;
}

.listItem a {
  text-decoration: none;
  color: white;
  padding: 5px;
}

.nav {
display: flex;
width: 100%;
height: 134px;
align-items: center;
padding: 0;
justify-content: space-around;
}

.navIcons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav h1 {
  display: none;
}

#logoDiv {
  height: 115px;
  width: 173px;
}

#logoDiv img {
  height: 100%;
  width: 100%;
}

ul {
  padding-left: 100px;
  display: flex;
  align-items: center;
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
}

li {
  padding: 15px 20px;
}

/* ------------HEADER SECTION--------------  */

#headerBanner {
  position: relative;
  width: 100%;
  height: 170px;
  z-index: -10;
}

.welcomeDiv {
  color: white;
  border-radius: 40px;
  height: 500px;
  margin-top: -25px;
  z-index: 10;
}

.welcomeDiv h1 {
  font-size: 30px;
  padding: 10px;
}

.welcomeDiv p {
  padding: 10px;
  font-size: 15px;
}

#videoDiv {
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 250px;
  width: 94%;
}

.chicken {
  position: absolute;
  bottom: -120px;
  left: -5px;
}

#videoWindow {
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 25px;
  border: green solid 5px;
  z-index: 10;
  top: -45px;
}

/* ------------Character SECTION--------------  */

.characterSection {
  padding-top: 90px;
}

.imgAndName {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding-bottom: 15px;
}


.imgAndName h1 {
  font-size: 40px;
}

.charDiv {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.col {
  flex-direction: column-reverse !important;
}


.charImgDivs {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 173px;
  width: 131px;
}

.charImgDivBone {
  margin-right: auto;
  position: relative;
  height: 173px;
  width: 131px;
}

.charImgDivRooster {
  margin-left: auto;
  position: relative;
  height: 173px;
  width: 131px;
}

.charImgDivs img {
  height: 100%;
  width: 100%
}

#tBoneImg {
  height: 100%;
  width: 100%
}

#broosterImg {
  height: 100%;
  width: 100%
}

.descBox {
  border-radius: 40px;
  color: white;
  height: 335px;
  /* padding-bottom: 25px; */
}

.funFacts {
  text-align: right;
  padding-top: 20px;
  padding-right: 50px;
  padding-bottom: 25px;
}

.descBox li {
  direction: rtl;
  text-align: right;
  padding-top: 0;
  padding-right: 50px;
}

.descBox button {
  padding-top: 10px;
  text-align: center;
  width: 50%;
  height: 40px;
  font-size: 40px;
  background-color: white;
}

.buttonDiv {
margin-top: 30px;
}

button h2 {
  font-size: 17px;
}

#cocoAbout {
  height: auto;
}

/* ------------VIDEO SECTION--------------  */

#imgDiv {
  height: 135px;
  width: 156px;
}

#imgDiv img {
  height: 100%;
  width: 100%;
}

.topDiv h1 {
  padding-top: 25px;
  padding-bottom: 25px;
  font-size: 30px;
}

.moreVidSection {
  position: relative;
  height: 211px;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.vid {
  border-radius: 15px;
  box-shadow: 3px 5px grey;
  padding-top: 10px;
  height: 100%;
  width: 100%;
}

/* ------------lyrics SECTION--------------  */

#lyricsSection {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 300px;
}

#lyricsSection p {
  font-size: 15px;
  margin-right: 10px;
  margin-left: 10px;
}

/* ------------follow SECTION--------------  */
#followSection {
  position: relative;
  padding-top: 120px;
  padding-bottom: 120px;
}


#followSection h1 {
  font-size: 30px;
  margin-left: 10%;
  margin-right: 10%;
}

.followLinks {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.followLinks img {
  margin: 10px;
  height: 65px;
  width: 50px;
}

/* !!!!!!!! remember to change this display when destop view */
#potatoBag {
  height: 113px;
  width: 107px;
  left: -18px;
  bottom: 0;
  position: absolute;
}

#potatoBag2 {
  position: absolute;
  height: 114px;
  width: 95px;
  right: -5px;
  bottom: 10px
}

/* ------------story SECTION--------------  */

.whySugarRush div {
border-radius: 50px;
}

.whySugarRush h1 {
  padding-top: 10px;
  margin-top: 20px;
  }

.whySugarRush p {
    color: white;
  }

.midImageDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 192px;
  width: 100%;
  }

.midImageDiv img { 
  margin-bottom: -45px;
  height: 100%;
  width: 176px;
  }

  .historyWithSon div {
    border-radius: 40px;
  }

  .historyWithSon h1 {
    padding-top: 25px;
    color: white;
    text-align: right;
  }

  .historyWithSon p {
    color: white;
    text-align: left;
  }

  /* ------------footer SECTION--------------  */

  .footBanner {
    width: 80%;
    margin-right: 10%;
    margin-left: 10%;
    height: 97px;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .footBanner img {
    height: 100%;
    width: 100%;
  }

   /* ------------ABOUT SECTION--------------  */

  #aboutPage {
    display: flex;
    flex-direction: column;
  }

   #aboutBanner {
     height: 160px;
     width: 100%;

   }

   #aboutBanner img {
     position: relative;
    height: 100%;
    width: 100%;
    z-index: -10;
  }

  .learnEnglish {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-top: -20px;
    background-color: #56d0fa;
    color: #e91572;
    border-radius: 40px;
    height: 250px;
    z-index: inherit;
  }
  
  .learnEnglish h1 {
    font-size: 30px;
  }
  .followingCast {
  
  }

  .aboutImgWrap {
    height: 250px;
  }

  .aboutImgWrap img {
    height: 100%;
    padding-bottom: 15px;
  }

  #landscape {
    height: 150px;
    width: 100%;
  }

  #landscape img {
    height: 100%;
    width: 100%;
  }

  .moreFriendsAndButton button {
    border-radius: 15px;
    margin-top: 20px;
  }

  .moreFriendsAndButton h2 {
    font-size: 60px !important;
  }

  .madeByAbout {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .madeByAbout h2 {

    color: white;
    padding: 0;
    padding-top: 10px;
  }
  

  .moreFriendsAndButton button h2 {
    padding: 3px;
    font-size: 25px !important;
  }

  .moreFriendsAndButton a {
    text-decoration: none;
  }

  .aboutDesc {
    color: white;
    border-radius: 40px;
    height: 309px;
    width: 100%;
    margin-bottom: 50px;
  }

  .aboutDesc h1 {
    text-align: right;
    padding-right: 50px;
    padding-top: 30px;
  }

  .aboutDesc p {
    padding-top: 30px;
    text-align: right;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 20px;
  }

  .chickAndBench {
    position: relative;
  }

  
  #lilChickOnBench {
    position: absolute;
    top: -290px;
    right: 55px;
    height: 60px;
    width: 56px;
  }
  
  #lilChickOnBench img {
    height: 100%;
    width: 100%;
  }

  #lilBench {
    position: absolute;
    right: 0;
    top: -230px;
    height: 92px;
    width: 167px;
  }

  #lilBench img {
    height: 100%;
    width: 100%;
  }

  #jumpingTBone {
    margin-top: 65px;
  }

.twoChickOnBench {
  position: relative;
}

#poussinClo {
  position: absolute;
  top: -185px;
  height: 39px;
  width: 37px;
  z-index: 10;
}

#poussinClo2 {
  position: absolute;
  top: -185px;
  left: 50px;
  height: 39px;
  width: 37px;
  z-index: 10;
}

#cloture {
  position: absolute;
  top: -150px;
  height: 97px;
  width: 160px;
}

#poussinClo img {
  height: 100%;
  width: 100%;
}

#poussinClo2 img {
  height: 100%;
  width: 100%;
}

#cloture img {
  height: 100%;
  width: 100%;
}

.madeByAbout {
  margin-top: -40px;
  height: 200px;
  border-radius: 40px;
  margin-bottom: 80px;
}

.madeByAbout h1 {
  color: white
}

.madeByAbout p {
  color: white
}


   /* ------------Media SECTION--------------  */
  
   .pressRoomSection {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    margin-top: -20px;
    background-color: #56d0fa;
    color: #e91572;
    border-radius: 40px;
    height: 250px;
    z-index: inherit;
  }

  #mediaBanner {
    height: 225px;
    width: 100%;
  }

  #mediaBanner img {
    height: 100%;
    width: 100%;
  }

  .featuredSection {
    padding-top: 50px;
  }

  .featuredSection h1 {
    padding-top: 50px;
    padding-bottom: 50px;
  }

   .featuredImgs {
    display: flex;
    align-items: center;
    flex-direction: column;
   }

   .featuredImgs img {
    width: 50%;
    padding: 15px;
   }

  .articleSection {
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .articleSection h2 {
    font-size: 40px;
    margin-bottom: 80px;
  }

  .articleSection h3 {
    padding-top: 5px;
   font-size: 30px;
  }

  .articleSection p {
  margin-left: 10%;
  margin-right: 10%;
  padding-top: 5px;
   font-size: 18px;
  }

  .mediaArticles img {
    height: 200px;

  }

  #dogDancing img {
     height: 50%;
     width: 400px;
  }

  /* ------------------CONTACT section---------------  */

#banner {
  position: relative;
  width: 100%;
  z-index: -10;
}

#banner img {
  position: relative;
  width: 100%;
  z-index: -10;
}

.formDiv {
  padding-top: 55px;
  border-radius: 25px;
  margin-top: -20px;
  z-index: 10;
  padding-left: 2vw;
}

.formDiv h1 {
  font-size: 40px;
  color: white;
  text-align: left;
}

#emailForm {
  display: flex;
  flex-direction: column;
  padding-bottom: 2%;
}

.nameAndEmail {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}

.nameAndEmail #Name {
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
  height: 53px;
  border-radius: 10px;
  margin-bottom: 2%;
}

.nameAndEmail #email {
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
  height: 53px;
  border-radius: 10px;
  margin-bottom: 2%;
}

#message {
  height: 347px;
  border-radius: 10px;
  margin-left: 5%;
  margin-right: 5%;
  width: 90%;
  margin-bottom: 2%;
}

#emailForm button {
  border-radius: 25px;
  margin-left: 40%;
  margin-right: 40%;
  width: 20%;
}

#emailForm button h3 {
  font-size: 20px;
}

.contactImgs {
  padding-top: 5vh;
  display: flex;
  flex-direction: column-reverse;
} 

.imgDiv1 {
  display: flex;
  height: 120px;
  margin-left: 0;
  width: 100%;
}

.imgDiv1 img {
  height: 100%;
}

.imgDiv2 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -10px;
  width: 100%;
}

.imgDiv2 img {
  height: 100px;
  width: 100px;
}

.grid {
  width: 100%;
}

.row1Contact {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.houseContact {
  height: 414px;
  width: 100%;
}

.houseContact img {
  height: 100%;
  width: 100% ;
}


.followUsContact {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
}

.followUsContact h1 {
  margin: 0;
}

.contactLinkWrap {
  display: flex;
}

.contactFollowLinks {
  margin: 40px;
  height: 148px;
  width: 172px;
}

.row2Contact {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contactButtonDiv {
  text-decoration: none;
  margin-top: 100px;
  margin-bottom: 50px;
}

.contactButtonDiv a {
  text-decoration: none;
}

.contactButtonDiv button {
  border-radius: 15px;
  width: 100%;
  color: white;
 }

.contactButtonDiv button h2 {
text-decoration: none;
 color: white;
 font-size: 35px;
}

.bottomGrid {
  position: relative;
}

#wackyContact {
  display: flex;
  height: 200px;
  margin-left: -50px;
}


}